import 'react-phone-number-input/style.css';
import { useCallback, useState, useEffect } from 'react';
import firebase from 'firebase';
import PhoneInput from 'react-phone-number-input/input';
import CodeInput from 'react-verification-code-input';

export default function LoginPhoneNumber() {
  const [number, setNumber] = useState('');
  const [confirmation, setConfirmation] = useState(null);
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('login-button', {
      'size': 'invisible',
      'callback': _response => {
      },
      'expired-callback': () => {
      },
    });
  }, []);

  const login = useCallback(async () => {
    console.log('verifying');
    const confirmResult = await firebase.auth().signInWithPhoneNumber(number, window.recaptchaVerifier);
    setConfirmation(confirmResult);
  }, [number]);

  const confirmCode = useCallback(async (finalCode) => {
    if (confirmation && finalCode.length === 6) {
      setLoading(true);
      await confirmation.confirm(finalCode);
      setLoading(false);
    }
  }, [confirmation]);

  return (
    <div className='login-form'>
      { !confirmation ? (
        <>
          <div className='form-input'>
            <PhoneInput
              placeholder='Enter your phone #'
              country='US'
              value={number}
              onChange={setNumber}
            />
            <label>Your Phone Number</label>
          </div>
          <button id='login-button' onClick={login}>Text me a code</button>
        </>
      ): (
        <>
          <h3>Enter the code you receive via text</h3>
          <CodeInput
            value={code}
            onChange={setCode}
            onComplete={confirmCode}
            fields={6}
            loading={loading}
          />
        </>
      )}
    </div>
  );
}
