import { useState, useEffect } from 'react';
import firebase from 'firebase';
import moment from 'moment';
import MarkdownRenderer from 'react-markdown-renderer';
import CalendarLinks from './CalendarLinks';
import Rsvp from './Rsvp';
import { eventDescription } from './constants';

export default function Event({ invite }) {
  const [info, setInfo] = useState(null);

  useEffect(() => {
    firebase.firestore().collection('event').doc('main').get().then(doc => {
      setInfo({
        name: doc.get('name'),
        description: eventDescription,
        when: doc.get('when'),
        whenEnd: doc.get('whenEnd'),
        where: doc.get('where'),
        whereInstruction: doc.get('whereInstruction'),
      });
    });
  }, []);

  if (!info) {
    return <div />;
  }

  return (
    <>
      <img src='/bezos-carlos-cartoon.png' className="bezos-banner" alt="banner" />
      <div className="info">
        <h1>{info.name}</h1>
        <h2>You're invited, {invite.firstName}! 🥳</h2>
        <Rsvp invite={invite} />
        { invite.rsvp && <CalendarLinks info={info} /> }
        <div className='details'>
          <h3>🕜<span>When:</span> {moment(info.when.toDate()).format('dddd, MMMM Do @ h:mma')}</h3>
          <h3>📍<span>Where:</span> {info.where}</h3>
          <h3>🚪<span>How to Get In:</span> {info.whereInstruction}</h3>
          <MarkdownRenderer markdown={info.description.replace('\\n', '\n')} />
        </div>
      </div>
    </>
  );
}

