export const eventDescription = `
Soon, three historic events will have happened on July 20th.

**On July 20, 1969**, Apollo 11 landed on the moon, marking humanity’s first steps into the heavens.

**On July 20, 1992**, Carlos Diaz-Padron was born in the middle of the worst hurricane to ever hit Florida.

And soon, **on July 20, 2021**, fellow cuban Jeffrey Preston Bezos will launch into space, paving the way for every young ~~billionaire~~ person to dream of reaching the stars.

(fun fact: among those traveling with him will be [Wally Funk](https://nypost.com/2021/07/02/why-jeff-bezos-chose-this-female-astronaut-from-nasas-mercury-13/), one of the astronauts from the female Mercury 13 program that never made it to space because sexism. she’s finally going! so that’s nice)

All of these are clearly equally historic events, so it only makes sense to celebrate them together!

- I rented out a sweet space in Chelsea that has a patio with a great view for the night
- We’ll have food and drinks (no need to bring your own)
- There will be music
- Bezos’ flight will be shown on a big TV for ~ambiance~
- [Just like Apollo 11](https://www.businessinsider.com/nixon-astronaut-death-moon-disaster-speech-2017-7), I will write two speeches: one if he makes it, and one if he doesn’t
`;
