import { useCallback } from 'react';
import firebase from 'firebase';

export default function Rsvp({ invite }) {
  const going = useCallback(() => {
    firebase.firestore().collection('invitees').doc(invite.phone).update({
      rsvp: true,
    });
  }, [invite]);

  const notGoing = useCallback(() => {
    firebase.firestore().collection('invitees').doc(invite.phone).update({
      rsvp: false,
    });
  }, [invite]);

  return (
    <div className='rsvp-container'>
      <p>Are you going?</p>
      <button className={invite.rsvp === true ? 'selected' : undefined} onClick={going}>Yes 👍{ invite.rsvp === true ? '🥳' : '' }</button>
      <button className={invite.rsvp === false ? 'selected' : undefined} onClick={notGoing}>No 👎{ invite.rsvp === false ? '🙁' : '' }</button>
    </div>
  );
}

