import { useCallback } from 'react';
import moment from 'moment';
import ical from 'ical-generator';

export default function CalendarLinks({ info }) {
  const openGcal = useCallback(() => {
    const start = moment(info.when.toDate()).format('YYYYMMDDTHHmmssZ');
    const end = moment(info.whenEnd.toDate()).format('YYYYMMDDTHHmmssZ');

    const gcalLink = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(info.name)}&dates=${encodeURIComponent(start)}%2F${encodeURIComponent(end)}&details=${encodeURIComponent(info.description)}&location=${encodeURIComponent(info.where)}`;
    window.open(gcalLink, '_blank');
  }, [info]);

  const openIcal = useCallback(() => {
    const start = moment(info.when.toDate());
    const end = moment(info.whenEnd.toDate());

    const cal = ical({ name: 'cal' });
    const calEvent = cal.createEvent({
      start,
      end,
      summary: info.name,
      description: info.description,
      location: info.where,
      url: 'https://launchparty.carlos-dp.com',
    });
    window.open('data:text/calendar;charset=utf8,' + escape(calEvent.toString()));
  }, [info]);

  return (
    <>
      <p>Great! Add it to your calendar:</p>
      <div className='calendar-links'>
        <button onClick={openGcal}>🗓 Add to Google Calendar</button>
        <button onClick={openIcal}>🗓 Add to Apple Calendar/Outlook</button>
      </div>
    </>
  );
}
