import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import firebase from 'firebase';

firebase.initializeApp({
  apiKey: "AIzaSyAX0O0hp5IwEXZ2UEqxweGmlnDSUrOyzvM",
  authDomain: "bezos-launch-party.firebaseapp.com",
  projectId: "bezos-launch-party",
  storageBucket: "bezos-launch-party.appspot.com",
  messagingSenderId: "279936064660",
  appId: "1:279936064660:web:7cb79f407cebc19e70a881",
  measurementId: "G-8H6LH1NL40",
});
firebase.analytics();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
