import './App.css';
import { useState, useEffect, useCallback } from 'react';
import firebase from 'firebase';
import Login from './LoginPhoneNumber';
import Event from './Event';

function App() {
  const [user, setUser] = useState(null);
  const [invite, setInvite] = useState(undefined);

  const onInviteChange = useCallback((invite) => {
    if (invite.exists) {
      setInvite({
        phone: invite.id,
        firstName: invite.get('first_name'),
        lastName: invite.get('last_name'),
        rsvp: invite.get('rsvp'),
      });
    } else {
      setInvite(null);
    }
  }, []);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      setUser(user);

    });
  }, [onInviteChange]);

  useEffect(() => {
    if (user) {
      return firebase.firestore().collection('invitees').doc(user.phoneNumber).onSnapshot({
        next: onInviteChange,
        error: console.error,
      });
    }
  }, [user, onInviteChange]);

  const logout = useCallback(() => {
    firebase.auth().signOut();
  }, []);

  let content = null;

  if (!user) {
    content = (
      <div className='auth-content'>
        <Login />
      </div>
    );
  } else if (invite === undefined) {
    content = <div />;
  } else if (invite === null) {
    content = (
      <div className='auth-content'>
        <p>✋ Hmm... we can't find your invite</p>
        <button onClick={logout}>Try another number</button>
      </div>
    );
  } else {
    content = (
      <>
        <Event invite={invite} />
        <button onClick={logout}>Not {invite.firstName}?</button>
      </>
    );
  }

  return (
    <div className="main">
      <div className="main-content">
        {content}
      </div>
    </div>
  );
}

export default App;
